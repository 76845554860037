<template>
  <div>
    <a-form
      class="ant-advanced-search-form content-container"
      name="advanced_search"
    >
      <div class="filterFields">
        <a-form-item label="Бренд" class="filterFormItems">
          <a-select
            v-model="filterBrand"
            placeholder="Бренд"
            class="selects"
            default-value="1"
          >
            <template v-for="brand in brandsList">
              <a-select-option :key="brand.name" :value="brand.name">
                {{ brand.name }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Статус" class="filterFormItems">
          <a-select
            v-model="filterStatus"
            placeholder="Статус"
            class="selects"
            default-value="1"
          >
            <template v-for="status in orderStatuses">
              <a-select-option :key="status.id" :value="status.id">
                {{ status.name }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Период" class="filter-form-items">
          <a-range-picker v-model="filterRange" :format="dateFormat" />
        </a-form-item>
        <a-form-item v-if="isOwner" label="Код дилера" class="filterFormItems">
          <a-select
            v-model="filterDealerId"
            placeholder="Код дилера"
            class="selects"
            default-value="1"
            :show-search="true"
            option-filter-prop="children"
          >
            <template v-for="dealer in dealersList">
              <a-select-option :key="dealer.id" :value="dealer.id">
                {{ dealer.code }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item
          v-if="isDealer || isBuyerForDealers"
          label="Код дилера"
          class="filterFormItems"
        >
          <a-select
            v-model="filterDealerId"
            placeholder="Код дилера"
            class="selects"
            default-value="1"
            :show-search="true"
            option-filter-prop="children"
          >
            <template
              v-for="dealer in isBuyerForDealers
                ? [...dcDealersList, user.client]
                : dealersList"
            >
              <a-select-option :key="dealer.id" :value="dealer.id">
                {{ dealer.code }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="Номер заказа" class="filterFormItems">
          <a-input
            v-model="filterOrderUuid"
            style="margin-bottom: 5px"
            placeholder="Номер заказа"
          ></a-input>
        </a-form-item>
      </div>
      <a-row class="buttons">
        <a-col span="6">
          <a-button type="primary" @click="filterByNum">Фильтр</a-button>
          <a-button @click="clearFilter">Очистить</a-button>
          <a-button v-if="isOwner" @click="exportExcel">
            Выгрузить в excel
          </a-button>
        </a-col>
        <a-col span="2"></a-col>
        <a-col span="16" style="text-align: right">
          <a-button-group>
            <template v-for="orderStatus in showStatusButtons">
              <a-button :key="orderStatus.id" @click="setStatus(orderStatus)">
                {{ orderStatus.action_name }}
              </a-button>
            </template>
          </a-button-group>
        </a-col>
      </a-row>
    </a-form>
    <div class="content-container">
      <a-table
        v-if="isBuyer || isDealer"
        bordered
        :columns="columns"
        :data-source="orders"
        :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
        :rowKey="row => row.uuid"
        :rowClassName="
          record => (record.is_read ? 'activeNotificationRow' : null)
        "
        class="ordertable"
      >
        <span slot="check" slot-scope="text, record, index">
          <a-checkbox :checked="text" @change="handleCheck($event, index)" />
        </span>
        <template slot="orderStatus" slot-scope="text">
          <a-tag>
            <span v-if="text === 'created'">Новый</span>
            <span v-else-if="text === 'completed'">Успешный</span>
            <span v-else-if="text === 'error'">Ошибка</span>
            <span v-else-if="text === 'in_process'">В процессе</span>
            <span v-else-if="text === 'declined'">Отклонен</span>
            <span v-else-if="text === 'warning'">Частично подтверждён</span>
          </a-tag>
        </template>

        <template slot="uuid" slot-scope="item, record">
          <a @click="handleUuid(record)">{{ item }}</a>
        </template>

        <template slot="created_at" slot-scope="item">
          <span>{{ item | date }}</span>
        </template>

        <template slot="delivery_date" slot-scope="item">
          <span>{{ item | date }}</span>
        </template>

        <template slot="positionCount" slot-scope="items">
          <span>{{ items.length }}</span>
        </template>

        <template slot="products_price" slot-scope="item">
          <span>{{ item | toCurrency }}</span>
        </template>
      </a-table>
      <a-table
        v-if="isOwner"
        bordered
        :columns="columns"
        :data-source="orders"
        :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
        :rowKey="row => row.uuid"
        :rowClassName="
          record => (record.is_read ? 'activeNotificationRow' : null)
        "
        class="ordertable"
      >
        <template slot="uuid" slot-scope="item, record">
          <a @click="handleUuid(record)">{{ item }}</a>
        </template>
        <template slot="orderStatus" slot-scope="text">
          <a-tag>
            <span v-if="text === 'created'">Новый</span>
            <span v-else-if="text === 'completed'">Успешный</span>
            <span v-else-if="text === 'error'">Ошибка</span>
            <span v-else-if="text === 'in_process'">В процессе</span>
            <span v-else-if="text === 'declined'">Отклонен</span>
            <span v-else-if="text === 'warning'">Частично подтверждён</span>
          </a-tag>
        </template>

        <template slot="created_at" slot-scope="item">
          <span>{{ item | date }}</span>
        </template>

        <template slot="delivery_date" slot-scope="item">
          <span>{{ item | date }}</span>
        </template>

        <template slot="positionCount" slot-scope="items">
          <span>{{ items.length }}</span>
        </template>

        <template slot="products_price" slot-scope="item">
          <span>{{ item | toCurrency }}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment"
import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"
import { DatePicker } from "ant-design-vue"
import api from "@/utils/api"

export default {
  name: "OrdersList",
  components: {
    ARangePicker: DatePicker.RangePicker,
  },
  mounted() {
    this.fetchOrders()
    this.fetchOrderStatuses()
    this.fetchBrandsList()
    if (this.user.dealer) {
      this.fetchDealersList(this.user.dealer.id)
    } else {
      this.fetchDealers()
    }
  },
  data() {
    return {
      filterText: "",
      filterDealerId: "",
      filterOrderUuid: "",
      filterBrand: "",
      filterStatus: "",
      filterRange: [],
      dateFormat: "DD.MM.YYYY",
      showStatusButtons: [],
    }
  },
  computed: {
    ...mapGetters({
      orders: "orders/orders",
      isOwner: "auth/isOwner",
      isBuyer: "auth/isBuyer",
      user: "auth/user",
      isDealer: "auth/isDealer",
      isBuyerForDealers: "auth/isBuyerForDealers",
      orderStatuses: "orders/orderStatuses",
      brandsList: "clients/brandsList",
      dealersList: "dealers/manufacturerDealers",
      dcDealersList: "auth/dcClientCodes",
      statusMethods: "orders/statusMethods",
    }),
    columns() {
      const list = [
        // {
        //   title: "",
        //   dataIndex: "check",
        //   width: 30,
        //   scopedSlots: { customRender: "check" },
        // },
        {
          title: "Номер заказа",
          dataIndex: "uuid",
          width: 200,
          align: "center",
          scopedSlots: { customRender: "uuid" },
        },
        {
          title: "Название дилера",
          dataIndex: "client_name",
          key: "client_name",
          align: "center",
        },
        // {
        //   title: "Бренд",
        //   dataIndex: "payment_type",
        //   width: 150,
        // },
        // {
        //   title: "Наименование",
        //   dataIndex: "name",
        //   key: "name",
        //   ellipsis: true,
        // },
        {
          title: "Сумма",
          dataIndex: "price",
          align: "center",
          scopedSlots: { customRender: "products_price" },
        },
        {
          title: "Количество позиций",
          dataIndex: "products_count",
          key: "products_count",
          align: "center",
          scopedSlots: { customRender: "products_count" },
        },
        {
          title: "Статус",
          dataIndex: "status_name",
          align: "center",
          scopedSlots: { customRender: "status_name" },
        },
        {
          title: "Дата создания",
          dataIndex: "created_at",
          width: 150,
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Предварительная дата доставки",
          dataIndex: "delivery_date",
          width: 160,
        },
        {
          title: "Комментарий",
          dataIndex: "last_comment",
          width: 160,
        },
      ]

      // if (this.isBuyer) {
      //   return list.filter(item => item.title !== "Клиент")
      // }

      return list
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: "orders/getOrders",
      fetchOrderStatuses: "orders/getOrderStatuses",
      fetchBrandsList: "clients/fetchBrandsList",
      fetchDealersList: "dealers/fetchManufacturerDealers",
      fetchDealers: "dealers/fetchDealers",

      setStatusInWork: "orders/setStatusInWork",
      setStatusConfirmed: "orders/setStatusConfirmed",
      setStatusCompleted: "orders/setStatusCompleted",
      setStatusRefund: "orders/setStatusRefund",
      setStatusConsideration: "orders/setStatusConsideration",
      setStatusRefundConfirmed: "orders/setStatusRefundConfirmed",
      setStatusCorrection: "orders/setStatusCorrection",
      setStatusCanceledSupplier: "orders/setStatusCanceledSupplier",
      setStatusCorrectionCompleted: "orders/setStatusCorrectionCompleted",
      setStatusCanceledDealer: "orders/setStatusCanceledDealer",
      setStatusRefundCanceled: "orders/setStatusRefundCanceled",
      setStatusEdited: "orders/setStatusEdited",
      setStatusCanceledManufacturer: "orders/setStatusCanceledManufacturer",
      setStatusUnderConsideration: "orders/setStatusUnderConsideration",
      setStatusInStock: "orders/setStatusInStock",
      setStatusInTransit: "orders/setStatusInTransit",
      setStatusShippedSupplier: "orders/setStatusShippedSupplier",
      setStatusRefundCompleted: "orders/setStatusRefundCompleted",
    }),
    ...mapMutations({
      setField: "orders/SET_ORDER_FIELD",
      setOrders: "orders/SET_LIST",
    }),
    async setStatus(orderStatus) {
      let order, query
      for (const index in this.orders) {
        order = this.orders[index]
        if (order.check) {
          const method = this.statusMethods[orderStatus.id]["m"]
          let formData = new FormData()
          formData.append("orderUuid", order.uuid)
          try {
            await this[method](formData)
            query = { index, key: "status_id", value: orderStatus.id }
            this.setField(query)
            query = { index, key: "status_name", value: orderStatus.name }
            this.setField(query)
          } catch (error) {
            if (error.response) {
              message.error(error.response.data.message)
            }
          }
        }
      }
    },

    async getActions(uuid) {
      const result = await axios.get(`${API_PREFIX}/order/${uuid}/actions`)
      this.showStatusButtons = result.data.data
    },

    handleCheck: function (event, index) {
      const query = { index, key: "check", value: event.target.checked }
      this.setField(query)
    },
    handleUuid(record) {
      this.changeNotificationStatus(record)
      window.location.href = `/order/${record.uuid}`
    },
    displayDate(value) {
      const createdAt = new Date(value)
      const day =
        createdAt.getUTCDate() < 10
          ? `0${createdAt.getUTCDate()}`
          : createdAt.getUTCDate()
      const month =
        createdAt.getUTCMonth() < 10
          ? `0${createdAt.getUTCMonth() + 1}`
          : createdAt.getUTCMonth() + 1
      return `${day}.${month}.${createdAt.getUTCFullYear()}`
    },
    async changeNotificationStatus(record) {
      const infoForChangeNotification = {
        id: record.id,
        user_id: this.user.id,
        is_read: "1",
      }
      try {
        await axios.put(`${API_PREFIX}/notice`, infoForChangeNotification)
      } catch (error) {
        message.error(error.message)
      }
    },
    async filterByNum() {
      let filterArray = this.orders
      if (this.filterText !== "") {
        const filterTextArray = this.orders.filter(
          item => item.uuid === this.filterText
        )
        filterArray = filterTextArray.filter(
          item => item.status_id === this.filterStatus
        )
      } else if (this.filterBrand !== "") {
        filterArray = this.orders.filter(
          item => item.brands.indexOf(this.filterBrand) > -1
        )
      } else if (this.filterStatus !== "") {
        filterArray = this.orders.filter(
          item => item.status_id === this.filterStatus
        )
      } else if (this.filterDealerId !== "") {
        filterArray = this.orders.filter(
          item => item.client_id === this.filterDealerId
        )
      } else if (this.filterOrderUuid !== "") {
        filterArray = this.orders.filter(
          item => item.uuid === this.filterOrderUuid
        )
      } else if (this.filterRange.length > 0) {
        if (this.filterRange.length === 2) {
          filterArray = this.orders.filter(
            item =>
              item.created_at.substring(0, 10) >=
                this.filterRange[0].format("YYYY-MM-DD") &&
              item.created_at.substring(0, 10) <=
                this.filterRange[1].format("YYYY-MM-DD")
          )
        }
      }
      this.setOrders(filterArray)

      this.showStatusButtons = []
      if (filterArray.length > 0 && this.filterStatus !== "") {
        await this.getActions(filterArray[0].uuid).data
      }
    },
    clearFilter() {
      this.filterBrand = ""
      this.filterDealerId = ""
      this.filterOrderUuid = ""
      this.filterStatus = ""
      this.filterRange = []
      this.showStatusButtons = []
      this.fetchOrders()
    },
    exportExcel() {
      const params = {
        brand_id: this.filterBrand,
        dealer_id: this.filterDealerId,
        order_uuid: this.filterOrderUuid,
        status_id: this.filterStatus,
        dates: this.filterRange,
      }
      api.downloadFileFromPost(`/orders/export_excel`, params)
    },
  },
  watch: {
    checked() {},
  },
}
</script>

<style lang="scss" scoped>
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: flex;
  align-items: center;
}
.selects {
  width: 200px;
}
</style>
<style lang="scss">
.filterFields {
  .ant-form-item {
    flex-direction: column;
    align-items: flex-start;

    &:not(:first-child) {
      margin-left: 20px;
    }

    .ant-form-item-label {
      padding-left: 3px !important;
    }

    label {
      font-size: 12px;
    }
  }
}

.ordertable {
  .ant-table-column-title {
    color: rgba(0, 0, 0, 0.65);
  }

  tr {
    color: rgba(0, 0, 0, 0.85);
  }

  th {
    border-radius: 0;
  }
}

button.ant-btn {
  margin-left: 10px !important;
}
</style>
