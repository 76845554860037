import axios from "axios"
import config from "@/config"
var fileDownload = require("js-file-download")
axios.defaults.baseURL = process.env.VUE_APP_API_PATH
export default {
  apiPath: `${config.backendUrl}${config.apiPath}`,
  viewPath: `${config.backendUrl}${config.viewPath}`,
  url: null,

  getUrl(path) {
    return `${this.apiPath}${path}`
  },

  async get(path) {
    return await this._makeRequest(path, {
      method: "GET",
    })
  },

  async post(path, params) {
    return await this._makeRequest(path, {
      ...params,
      method: "post",
    })
  },

  async postJson(path, params) {
    const headers = params.headers || {}
    headers["Content-Type"] = "application/json"
    return this.post(path, {
      headers,
      data: params,
    })
  },

  async put(path, params) {
    return this._makeRequest(path, {
      ...params,
      method: "put",
    })
  },

  async putJson(path, params) {
    const headers = params.headers || {}
    headers["Content-Type"] = "application/json"
    return this.put(path, {
      headers,
      data: params,
    })
  },

  async delete(path) {
    return await this._makeRequest(path, {
      method: "DELETE",
    })
  },

  async _makeRequest(path, params) {
    axios.defaults.withCredentials = true
    const response = await axios({
      ...params,
      url: this.getUrl(path),
    })
    const result = response.data
    return result.data !== undefined ? result.data : result
  },

  async view(path, method = "GET", data = null) {
    axios.defaults.withCredentials = true
    const response = await axios({
      method: method,
      url: `${config.backendUrl}/view${path}`,
      data,
    })
    const result = response.data
    return result.data !== undefined ? result.data : result
  },

  async upload(path, formData) {
    axios.defaults.withCredentials = true
    const response = await axios({
      method: "POST",
      url: `${config.backendUrl}/upload${path}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    const result = response.data
    return result.data !== undefined ? result.data : result
  },

  async downloadFileFromPost(path, params = {}, filename = null) {
    axios.defaults.withCredentials = false

    return await axios({
      method: "POST",
      url: `${config.backendUrl}/download${path}`,
      data: params,
      responseType: "blob",
    }).then(response => {
      if (!filename && response.headers["content-disposition"]) {
        filename = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0]
        filename +=
          "." +
          response.headers["content-disposition"].split(".")[1].split(";")[0]
      }
      if (!filename) {
        filename = "exportfile"
      }
      fileDownload(response.data, filename)
    })
  },
  async downloadFileFromGet(path, filename = null) {
    axios.defaults.withCredentials = false

    return await axios({
      method: "GET",
      url: `${config.backendUrl}${path}`,
      responseType: "blob",
    }).then(response => {
      if (!filename && response.headers["content-disposition"]) {
        filename = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0]
        filename +=
          "." +
          response.headers["content-disposition"].split(".")[1].split(";")[0]
      }
      if (!filename) {
        filename = "exportfile"
      }
      fileDownload(response.data, filename)
    })
  },
}
