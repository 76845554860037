<template>
  <OrdersList />
</template>

<script>
import OrdersList from "@/components/orders/OrdersList"

export default {
  name: "Orders",
  components: {
    OrdersList,
  },
}
</script>
